import { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CatalogTab } from 'src/store/uiState/UiStateStore'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { SimpleModal } from 'src/ui-components'
import { observer } from 'mobx-react-lite'

interface VehicleRequiredModalProps {
  isMobile?: boolean
}

const VehicleRequiredModal = observer(
  (props: VehicleRequiredModalProps): ReactElement => {
    const { isMobile } = props
    const [showVehicleRequired, setShowVehicleRequired] = useState(true)
    const history = useHistory()
    const { uiStore } = StoreInstances

    const handleVehicleRequired = (): void => {
      setShowVehicleRequired(false)
      history.push('/home')
    }

    const handleGoToAstCatalog = () => {
      uiStore.selectedCatalogTab = CatalogTab.AST_CATALOG
    }

    return (
      <SimpleModal
        show={
          !StoreInstances.searchStore.currentVehicle?.engine &&
          showVehicleRequired
        }
        title="vehicleRequired"
        primaryActionText={
          isMobile ? 'goToASTCatalog' : 'goToAccessoriesSuppliesAndTools'
        }
        onPrimaryAction={handleGoToAstCatalog}
        secondaryActionText={isMobile ? 'selectAVehicle' : 'selectAVehicle'}
        onSecondaryAction={handleVehicleRequired}
        buttonsDirection={isMobile ? 'column' : 'row'}
        primaryButtonToTheLeft={isMobile}
      >
        vehicleRequiredForSearch
      </SimpleModal>
    )
  }
)

VehicleRequiredModal.displayName = 'VehicleRequiredModal'

export default VehicleRequiredModal
