import {
  CategoriesResponse,
  GroupsResponse,
  IPartItemResponse,
  IdsEnginesResponse,
} from 'src/features/partsCatalog/Selections/interfaces'
import { CatalogAPI } from './ApiInterface'
import { CatalogTreeParser } from '../CatalogTreeParser'
import { CatalogNode } from '../CatalogNode'
import { IdValueGeneric } from 'src/store/models/KeyValuePair'
import { Vehicle } from 'src/store/models/Vehicles'
import CatalogServiceProvider from 'src/services/CatalogServiceProvider'

export class PartsCatalogAPI implements CatalogAPI {
  SESSION_STORAGE_KEY

  constructor() {
    this.SESSION_STORAGE_KEY = 'selected-parts-catalog-tree'
  }

  fetchCategories = async (vehicle?: Vehicle): Promise<CategoriesResponse> => {
    const resp = await CatalogServiceProvider.fetchCategories(vehicle)
    return { categories: resp, selectedCategories: [] }
  }

  fetchGroups = async (
    categories: Array<IdValueGeneric<string, string>>,
    vehicle?: Vehicle
  ): Promise<GroupsResponse> => {
    const resp = await CatalogServiceProvider.fetchGroups(categories, vehicle)
    return { groups: resp, selectedGroups: [] }
  }

  fetchPartTypes = async (
    groups: Array<IdValueGeneric<string, string>>,
    vehicle: Vehicle
  ): Promise<IPartItemResponse | IdsEnginesResponse> => {
    const resp = await CatalogServiceProvider.fetchTerminologies(
      groups,
      vehicle
    )
    return resp
  }

  retrieveSelectionsFromSession = (parser: CatalogTreeParser): CatalogNode => {
    const savedJson = sessionStorage.getItem(this.SESSION_STORAGE_KEY)
    if (savedJson?.length > 3) return parser.parse(savedJson)
    return undefined
  }

  storeSelectionsOnSession = (selections: string): void => {
    sessionStorage.setItem(this.SESSION_STORAGE_KEY, selections)
  }

  isGfxSupported = (): boolean => {
    return true
  }
}
