import { observer } from 'mobx-react-lite'
import { ReactElement, useCallback, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { PartsCatalogType } from 'src/store/partsCatalog/PartsCatalog'
import { StoreInstances } from 'src/store/StoreInstancesContainer.ts'
import { MobileLaborLookupSteps } from './components/MobileLaborLookupSteps'
import { SelectionsInterface } from './interfaces'
import { SearchStoreContext } from 'src/store/search/SearchStore'
import VehicleRequiredModal from 'src/features/partsCatalog/Selections/VehicleRequiredModal.tsx'
import useSharedLaborSelectorMobileLogic from 'src/common-hooks/useSharedLaborSelectorMobileLogic.ts'
import { useLaborLookup } from 'src/store/labor/LaborStore'

interface Props extends SelectionsInterface {
  partGroupId?: string
  setHideTitle: (arg: boolean) => void
}

export const LaborLookupSelectorMobile = observer(
  (props: Props): ReactElement => {
    const { partGroupId } = props
    const { lookup: laborPartsCatalog } = useLaborLookup()
    const {
      categories,
      fetchCategories,
      fetchGroups,
      fetchPartTypes,
      resetStore,
    } = laborPartsCatalog

    const fetchData = useCallback(async () => {
      if (categories?.length === 0) {
        try {
          await fetchCategories(partGroupId)
          await fetchGroups(partGroupId)
          await fetchPartTypes()
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching labor data:', error)
        }
      }
    }, [
      categories?.length,
      fetchCategories,
      fetchGroups,
      fetchPartTypes,
      partGroupId,
    ])

    useEffect(() => {
      fetchData()
    }, [
      categories,
      fetchCategories,
      fetchData,
      fetchGroups,
      fetchPartTypes,
      partGroupId,
    ])

    useEffect(() => {
      resetStore()
    }, [resetStore])

    const { loading, setLoading, step, setStep, mode, setMode } =
      useSharedLaborSelectorMobileLogic(props)
    const history = useHistory()
    const {
      showFitmentConditionsDialog,
      hideFitmentConditionsDialog,
      setShowLaborLookupModal,
      setShowLaborResultModal,
    } = useUiStateStore()
    const searchStore = useContext(SearchStoreContext)
    const {
      fetchSpecificConditionQuestions,
      areAllQuestionsAnswered,
      processQuestions,
    } = useSpecificConditionStore()

    const setLaborLookup = setShowLaborLookupModal
    const setLaborResult = setShowLaborResultModal

    const goToSearchResults = (): void => {
      hideFitmentConditionsDialog()
      searchStore.searchByNavigation()
      history.push('/searchResults')
    }

    const goBack = (): void => {
      hideFitmentConditionsDialog()
    }

    const fetchAndShowResults = (): void => {
      setLaborLookup(false)
      setLaborResult(true)
    }

    const handleShowFitmentConditions = async () => {
      setLoading(true)
      try {
        const formattedVehicle = {
          ...StoreInstances.searchStore.currentVehicle,
          initialized: undefined,
        }

        const specificConditionQuestions =
          await fetchSpecificConditionQuestions(
            formattedVehicle,
            laborPartsCatalog.terminologies,
            PartsCatalogType.LABOR_SEARCH
          )
        if (
          specificConditionQuestions.length > 0 &&
          !areAllQuestionsAnswered()
        ) {
          setLaborLookup(false)
          showFitmentConditionsDialog({
            proceed: goToSearchResults,
            cancel: goBack,
          })
        } else {
          processQuestions()
          fetchAndShowResults()
        }
      } catch (_e) {
        // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
        console.error('Error fetching labor specific conditions')
      } finally {
        setLoading(false)
      }
    }

    return (
      <>
        <MobileLaborLookupSteps
          step={step}
          setStep={setStep}
          mode={mode}
          setMode={setMode}
          loading={loading}
          onClick={handleShowFitmentConditions}
          partsCatalog={laborPartsCatalog}
        />
        <VehicleRequiredModal isMobile />
      </>
    )
  }
)
