import { PartsRequest } from 'src/api/part/interface'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import {
  AstSearchResultsRequest,
  GFXPartsBinRequest,
  ISearchResultsResponse,
  InterchangeRequest,
  InterchangeSearchResultsResponse,
  InterchangeRequestData,
  LinesManufacturer,
  ManufacturerRequestData,
  PartDetails,
  PartDetailsParams,
  PartDetailsRequest,
  PartVehiclesResponse,
  PartsBinRequest,
  SearchRequestBase,
  SearchResultsRequest,
  SearchResultsResponse,
  InterchangePartsBinRequest,
} from 'src/store/models/SearchModels'
import { UserAttributeKey } from 'src/store/user/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'
import CatalogServiceProvider from './CatalogServiceProvider'

import authManager from '../api/security/Auth'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { CatalogIndexTracker } from 'src/api/metrics/CatalogIndexTracker'
import { defaultCredentials } from 'src/aesInit'
import { EmbeddedCommunicationsManager } from 'src/api/embedded/EmbeddedCommunicationsManager'
import {
  PartAvailabilityRequest,
  PartAvailabilityResponse,
  PartInfoResponse,
} from 'src/store/cart/ValidationInterfaces'
import { getServiceName } from 'src/helpers/getServiceName'
import { PartsList, ManufacturerList } from 'src/store/models/SearchModels'
import { CrossReferenceResponse } from 'src/api/crossReference/interface'

class PartServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'part', '1.1', '')
  }

  byPartsApi = async (
    reqBody: PartsRequest,
    excludeAlternates = false
  ): Promise<SearchResultsResponse> => {
    const resp = await this.post<SearchResultsResponse>(
      'part/byParts',
      reqBody,
      {
        params: {
          catalogId: 99,
          excludeAlternates,
        },
      }
    )
    return resp.data
  }

  getPartDomain = () =>
    StoreInstances?.userStore?.getUserAttribute(UserAttributeKey.partDomain)

  getGeneralSearchResults = async (
    endpointFullUrl: string,
    reqData: SearchResultsRequest
  ): Promise<SearchResultsResponse> => {
    const resp = await this.post<SearchResultsResponse>(
      endpointFullUrl,
      reqData,
      {
        params: {
          partDomain: this.getPartDomain(),
        },
      }
    )
    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  public beforeEachRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...config,
      params: {
        catalogId:
          StoreInstances.searchStore?.currentVehicle?.type?.id === undefined
            ? 111
            : StoreInstances.searchStore.currentVehicle.type.id,
        covListId: StoreInstances.userStore.preferences?.mclOrgId,
        countryId: StoreInstances.userStore.country.countryCode,
        buyerId:
          IS_INTEGRATED_VERSION &&
          EmbeddedCommunicationsManager.integrationInfo.allowAnyBuyer
            ? EmbeddedCommunicationsManager.getPartsBasketCreds().buyerId
            : undefined,
        ...config.params,
      },
      headers: {
        'Service-Name': getServiceName(),
        'Catalog-Index': CatalogIndexTracker.index,
        'Partner-Name': IS_INTEGRATED_VERSION
          ? EmbeddedCommunicationsManager.getPartsBasketCreds().partnerId
          : defaultCredentials.partnerId,
        ...config.headers,
      },
    }
  }

  getSearchResults = async (
    reqData: SearchResultsRequest
  ): Promise<SearchResultsResponse> => {
    const endpointFullUrl = `part/byNavigation`
    const resp = await this.getGeneralSearchResults(endpointFullUrl, reqData)
    return resp
  }

  getDiagramsSearch = async (
    partsBinId: string
  ): Promise<SearchResultsResponse> => {
    const endpointFullUrl = `bin/${partsBinId}/diagramparts`
    const resp = await this.get<SearchResultsResponse>(endpointFullUrl, {
      params: {
        partDomain: this.getPartDomain(),
      },
    })

    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  getAstPunchOutSearchResults = async (
    reqData: AstSearchResultsRequest
  ): Promise<SearchResultsResponse> => {
    const endpointFullUrl = `part/byASTNavigation`

    const resp = await this.post<SearchResultsResponse>(
      endpointFullUrl,
      reqData,
      {
        params: {
          catalogId: 7,
        },
        headers: {
          Authorization: await authManager.getCurrentToken(),
        },
      }
    )
    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  getSearchResultsByText = async (
    reqData: SearchRequestBase,
    searchText: string,
    queryOverrides?: Record<string, string>
  ): Promise<SearchResultsResponse> => {
    const searchBy = encodeURIComponent(searchText)
    const resp = await this.post<SearchResultsResponse>(
      `part/bySearchTxt/${searchBy}`,
      reqData,
      {
        params: {
          ...queryOverrides,
        },
      }
    )

    //await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  getAvailability = async (
    partsInfo: PartAvailabilityRequest
  ): Promise<PartInfoResponse[]> => {
    const res = await this.post<PartAvailabilityResponse>(
      'part/availability',
      partsInfo,
      {
        params: {
          catalogId: 99,
        },
        headers: {
          'Catalog-Index': 0,
        },
      }
    )
    const parts = res.data.parts || []
    return parts.map((part) => {
      /* MPV3-2600 - The endpoint is sending the wrong data type for the locationId field on locations that are valid, furthermore it is sending 
    non valid locations with the correct locationId data type.
    Thus leading to confusion and errors */
      const locations = part.locations
        .filter((l) => typeof l.locationId === 'number') // Collect only the valid locations which have the wrong data type (number)
        .map((l) => {
          return { ...l, locationId: l.locationId?.toString() }
        }) // Map the correct type to the wrong type
      return {
        ...part,
        locations,
      }
    })
  }

  getPartsBinResults = async (
    partsBinId: string,
    reqData: PartsBinRequest,
    queryOverrides?: Record<string, string>
  ): Promise<SearchResultsResponse> => {
    const resp = await this.post<SearchResultsResponse>(
      `bin/${partsBinId}/part`,
      reqData,
      {
        params: {
          ...queryOverrides,
        },
      }
    )

    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  getGfxPartsBinResults = async (
    partsBinId: string,
    reqData: GFXPartsBinRequest
  ): Promise<SearchResultsResponse> => {
    const resp = await this.post<SearchResultsResponse>(
      `bin/${partsBinId}/gfxpart`,
      reqData
    )

    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  getInterchangeResults = async (
    reqData: InterchangeRequest
  ): Promise<SearchResultsResponse> => {
    try {
      const resp = await this.post<InterchangeSearchResultsResponse>(
        `part/interchange/parts`,
        reqData
      )

      /* 
      MPV3-5810 - temporary disabled code - Nov 13th 2024
      const parts = resp?.data?.partsResult?.flatMap((g) =>
      g.parts.map((p) => ({
        ...p,
        heavyDutyGroup: g.group,
        partType: g.group.partType,
        // isLoadingPrices: !resp.data?.isSnwFinished, MPV3-5810 - temporary disabled code - Nov 13th 2024
        isLoadingPrices: false,
      }))
    )

    const mappedResp = { ...resp.data, parts } */
      const mappedResp = { ...resp.data, parts: [] } // Temporary line MPV3-5810

      await CatalogServiceProvider.handleMissedImages(mappedResp)
      return mappedResp
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (
        error.status === 400 &&
        error.response.data.code === 'PARTSDISPLAY_1003'
      ) {
        return {
          parts: [],
          invalidPart: true,
        }
      }
    }
    return {
      parts: [],
    }
  }

  getPriceForInterchangeParts = async (
    partsBinId: string,
    reqData: InterchangePartsBinRequest
  ): Promise<SearchResultsResponse> => {
    const resp = await this.post<InterchangeSearchResultsResponse>(
      `part/interchange/${partsBinId}/priceAndAvailability`,
      reqData
    )
    const parts = resp?.data?.partsResult?.flatMap((g) =>
      g.parts.map((p) => ({
        ...p,
        heavyDutyGroup: g.group,
        partType: g.group.partType,
        isLoadingPrices: !resp.data?.isSnwFinished,
      }))
    )

    const mappedResp = { ...resp.data, parts }

    await CatalogServiceProvider.handleMissedImages(mappedResp)
    return mappedResp
  }

  getPartVehicles = async (
    partsBinId: string,
    lineCode: number
  ): Promise<PartVehiclesResponse> => {
    const resp = await this.get<PartVehiclesResponse>(
      `bin/${partsBinId}/${lineCode}/vehicles`
    )
    return resp.data
  }

  clearPartsBinCache = (partsBinId: string): Promise<void> => {
    return this.delete(`bin/${partsBinId}`)
  }

  getLinesManufacturers = async (
    partsBinId: string
  ): Promise<LinesManufacturer[]> => {
    const resp = await this.post<LinesManufacturer[]>(
      `part/${partsBinId}/manufacturers`
    )
    return resp.data
  }

  getPartDetails = async (
    reqData: PartDetailsRequest,
    params: PartDetailsParams
  ): Promise<PartDetails> => {
    const resp = await this.post<PartDetails>(`part/detail`, reqData, {
      params: {
        allianceProductId: params.allianceProductId,
        lineCode: params.lineCode,
        partNumber: params.partNumber,
        orderNumber: params.orderNumber,
      },
    })
    return resp.data
  }

  getInterchangePartTypes = async (
    InterChangeString: string
  ): Promise<AxiosResponse<PartsList>> => {
    const resp = await this.post<PartsList>(`part/interchange/partTypes`, {
      interchangeText: InterChangeString,
    })
    return resp
  }

  getInterchangeManufacturerData = async (
    data: ManufacturerRequestData
  ): Promise<AxiosResponse<ManufacturerList>> => {
    const resp = await this.post<ManufacturerList>(
      `/part/interchange/manufacturers`,
      data
    )
    return resp
  }

  getAllInterchangeResults = async (
    reqData: InterchangeRequestData
  ): Promise<ISearchResultsResponse> => {
    const resp = await this.post<ISearchResultsResponse>(
      `part/interchange/parts`,
      reqData
    )
    return resp.data
  }

  getCrossReferenceData = async (payload): Promise<CrossReferenceResponse> => {
    try {
      const resp = await this.post<CrossReferenceResponse>(
        'part/interchange/crossReference',
        payload
      )
      if (resp.status === 200) {
        const newData = {
          ...resp.data,
          crossReferenceParts: (resp.data.crossReferenceParts || []).map(
            (part) => ({
              ...part,
              partNumber: part.partNumber ?? '',
              manufacturer: part.manufacturer ?? '',
              description: part.description ?? '',
            })
          ),
        }
        return newData
      }
      if (resp.status === 204) {
        return {
          crossReferenceParts: [],
          filters: [],
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (error: any) {
      if (error?.message === 'Request failed with status code 400') {
        throw new Error('searchTermShouldBeMinimum3Characters')
      } else {
        throw new Error(error.toString())
      }
    }
    return {
      crossReferenceParts: [],
      filters: [],
    }
  }
}

export default new PartServiceProvider()
