import { Grow } from '@mui/material'
import {
  Delete,
  Edit,
  Assignment,
  Build,
  HomeRepairService,
} from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { ReactElement, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { I18n, Translate } from 'src/i18n'
import { useVehicleSpecification } from 'src/store/vehicleSpecification/VehicleSpecificationStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { useUserStore } from 'src/store/user/UserStore'
import { UserAttributeKey } from 'src/store/user/interfaces'
import {
  PartsCatalogType,
  usePartsCatalog,
} from 'src/store/partsCatalog/PartsCatalog'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import { defaultTheme } from 'src/theme'
import LaborLookupDrawer from 'src/features/labor/lookup/LookupDrawer'
import { ExperianAutocheckButton } from 'src/features/customizeSearch/components/ExperianAutocheckButton'
import {
  useVehicleWidgetStore,
  VehicleWidgetDisplayState,
} from './store/VehicleWidgetStore'
import { StoreInstances } from '../../../store/StoreInstancesContainer'
import { VehicleSpecificationDrawer } from './components/VehicleSpecificationDrawer'
import LaborResultDrawer from '../../labor/results/LaborResultDrawer'
import { ServiceIntervalsDrawer } from './components/ServiceIntervalsDrawer'
import { Button } from 'src/ui-components'
import { useSearchStore } from 'src/store/search/SearchStore'
import { show as showNiceModal } from '@ebay/nice-modal-react'
import { VinDetails } from 'src/features/vehicleHistory/components/VinDetails'

interface SelectedVehicleProps {
  onEditVehicle: () => void
}

export const SelectedVehicle = observer(
  (props: SelectedVehicleProps): ReactElement => {
    const location = useLocation()
    const history = useHistory()
    const searchStore = useSearchStore()
    const enableGrowTransition =
      searchStore.showVehicleTransition &&
      location.pathname !== '/home' &&
      location.pathname !== '/searchResults'

    const { onEditVehicle } = props
    const { currentVehicle } = searchStore
    const { terminologies } = usePartsCatalog()
    const {
      fetchSpecificConditionQuestions,
      specificConditionsLoading,
      setSpecificConditionsLoading,
    } = useSpecificConditionStore()

    const {
      fetchVehicleSpecification,
      showVehicleSpecificationDrawer,
      setShowVehicleSpecificationDrawer,
    } = useVehicleSpecification()

    const vehicleWidgetStore = useVehicleWidgetStore()

    const {
      setShowLaborLookupModal,
      setShowLaborResultModal,
      showLaborResultModal,
      showServiceIntervalsDrawer,
      setShowServiceIntervalsDrawer,
    } = useUiStateStore()

    const userStore = useUserStore()
    const showLaborLookup =
      userStore.getUserAttribute(UserAttributeKey.epeFeatures_labor) === 'true'

    const [openSpecDrawerExcludeGroups, setOpenSpecDrawerExcludeGroups] =
      useState(false)

    const goToSearchResults = (): void => {
      StoreInstances.uiStore.hideFitmentConditionsDialog()
      searchStore.searchByNavigation()
      history.push('/searchResults')
    }

    const goBack = (): void => {
      StoreInstances.uiStore.hideFitmentConditionsDialog()
    }

    const handleEditFitmentConditions = async () => {
      try {
        setSpecificConditionsLoading(true)
        const formattedVehicle = {
          ...searchStore.currentVehicle,
          initialized: undefined,
        }
        const specificConditionQuestions =
          await fetchSpecificConditionQuestions(
            formattedVehicle,
            terminologies,
            PartsCatalogType.PARTS_SEARCH
          )
        if (specificConditionQuestions.length > 0) {
          StoreInstances.uiStore.showFitmentConditionsDialog({
            proceed: goToSearchResults,
            cancel: goBack,
          })
        }
      } finally {
        setSpecificConditionsLoading(false)
      }
    }

    const handleDeleteVehicle = () => {
      if (
        location.pathname === '/partsCatalog' ||
        location.pathname === '/searchResults'
      )
        history.push('home')
      searchStore.deleteCurrentVehicle()
      vehicleWidgetStore.setIsDrawerOpened(false)
      vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.select)
      StoreInstances.uiStore.displaySuccessNotification(
        'vehicleSuccessfullyDeleted'
      )
    }

    const handleShowSpecification = () => {
      fetchVehicleSpecification()
      setOpenSpecDrawerExcludeGroups(true)
    }

    const handleChangeVehicle = () => {
      vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.edit)
    }

    const handleLaborLookupClick = () => {
      showNiceModal(LaborLookupDrawer, {
        vehicle: currentVehicle,
        setLaborLookup: setShowLaborLookupModal,
        setLaborResult: setShowLaborResultModal,
      })
    }

    const handleShowSeviceIntervals = () => {
      setShowServiceIntervalsDrawer(true)
    }
    const showEditFitmentConditions =
      (searchStore?.currentVehicle?.specificConditions?.length || 0) > 0
    const subModel = currentVehicle.vehicleSpecs?.find(
      (obj) => obj.label === 'Series'
    )?.value

    // other motives changes
    const disableDetailsInfo = currentVehicle?.type?.id !== 111

    return (
      <Grow in appear={enableGrowTransition} timeout={1000}>
        <Wrapper>
          <VehicleInfo>
            <LeftSide>
              <FlexColumn>
                <SpaceBetweenRow>
                  <Title>
                    {currentVehicle.year?.value}&nbsp;
                    {currentVehicle.make?.value}&nbsp;
                    {currentVehicle.model?.value}&nbsp;
                  </Title>
                  <RightSide>
                    <EditButton onClick={onEditVehicle}>
                      <EditIcon>
                        <Edit style={{ marginRight: 4, fontSize: '18px' }} />
                      </EditIcon>
                      <I18n name="changeVehicle" />
                    </EditButton>
                    <EditButton onClick={handleDeleteVehicle}>
                      <DeleteIcon>
                        <Delete style={{ marginRight: 4, fontSize: '18px' }} />
                      </DeleteIcon>
                      <I18n name="clearVehicle" />
                    </EditButton>
                  </RightSide>
                </SpaceBetweenRow>
                <SubTitle>
                  {subModel?.length > 0 && subModel && (
                    <span>
                      <VehicleDetailLabel>
                        <I18n name="subModel" />:
                      </VehicleDetailLabel>
                      <VehicleDetail>{subModel}</VehicleDetail>
                    </span>
                  )}
                  {currentVehicle.engine?.value && (
                    <span>
                      <VehicleDetailLabel>
                        <I18n name="engine" />:
                      </VehicleDetailLabel>
                      <VehicleDetail>
                        {currentVehicle.engine.value}
                      </VehicleDetail>
                    </span>
                  )}
                  {currentVehicle.vin && (
                    <span>
                      <VehicleDetailLabel>
                        <I18n name="vin" />:
                      </VehicleDetailLabel>
                      <VehicleDetailLink
                        onClick={() =>
                          showNiceModal(VinDetails, {
                            VIN: currentVehicle?.vin,
                          })
                        }
                        type="button"
                      >
                        {currentVehicle.vin}
                      </VehicleDetailLink>
                    </span>
                  )}
                  {currentVehicle.plate && (
                    <span>
                      <VehicleDetailLabel>
                        <I18n name="license" />:
                      </VehicleDetailLabel>
                      <VehicleDetail>
                        {currentVehicle.state}, {currentVehicle.plate}
                      </VehicleDetail>
                    </span>
                  )}
                </SubTitle>
              </FlexColumn>

              {import.meta.env.VITE_FEATURE_VEHICLE_LINKS_ENABLED === 'true' &&
                !disableDetailsInfo && (
                  <DetailsInfo>
                    <DetailsInfoAction onClick={handleShowSpecification}>
                      <Assignment
                        fontSize="inherit"
                        style={{ margin: 'auto 0px' }}
                      />
                      <I18n name="specifications" />
                    </DetailsInfoAction>
                    <DetailsInfoAction onClick={handleShowSeviceIntervals}>
                      <Build
                        fontSize="inherit"
                        style={{ margin: 'auto 0px' }}
                      />
                      <I18n name="serviceIntervals" />
                    </DetailsInfoAction>
                    {showLaborLookup && (
                      <LaborLookupLink onClick={handleLaborLookupClick}>
                        <HomeRepairService
                          fontSize="inherit"
                          style={{ margin: 'auto 0px' }}
                        />
                        <I18n name="laborLookup" />
                      </LaborLookupLink>
                    )}
                    <ExperianAutocheckButton vin={currentVehicle?.vin} />
                    {showEditFitmentConditions &&
                      location.pathname === `/searchResults` && (
                        <EditFitmentConditions
                          onClick={() => {
                            handleEditFitmentConditions()
                          }}
                        >
                          {specificConditionsLoading ? (
                            <StyledSpinner animation="border" size="sm" />
                          ) : (
                            <StyledEditIcon />
                          )}
                          {Translate('editFitmentConditions')}
                        </EditFitmentConditions>
                      )}
                  </DetailsInfo>
                )}
            </LeftSide>

            <MobileActionButtons>
              <MobileDeleteButton>
                <Button
                  style={{ width: '100%' }}
                  variant="secondary"
                  onClick={handleChangeVehicle}
                  text="changeVehicle"
                  icon={
                    <Edit
                      style={{ fontSize: '18px', margin: '-4px 4px 0px 0px' }}
                    />
                  }
                />
              </MobileDeleteButton>
              <MobileEditButton>
                <Button
                  style={{ width: '100%' }}
                  text="clearVehicle"
                  variant="secondary"
                  onClick={handleDeleteVehicle}
                  icon={
                    <Delete
                      style={{ fontSize: '18px', margin: '-4px 4px 0px 0px' }}
                    />
                  }
                />
              </MobileEditButton>
            </MobileActionButtons>
          </VehicleInfo>
          {showLaborResultModal && (
            <LaborResultDrawer
              open={showLaborResultModal}
              setOpen={setShowLaborResultModal}
              setLaborOpen={setShowLaborLookupModal}
            />
          )}
          <VehicleSpecificationDrawer
            openWithFilters={showVehicleSpecificationDrawer}
            open={openSpecDrawerExcludeGroups}
            onClose={() => {
              setShowVehicleSpecificationDrawer(false)
              setOpenSpecDrawerExcludeGroups(false)
            }}
          />
          {showServiceIntervalsDrawer && (
            <ServiceIntervalsDrawer
              open={showServiceIntervalsDrawer}
              onClose={() => {
                setShowServiceIntervalsDrawer(false)
              }}
              showEditFitmentConditions={showEditFitmentConditions}
              handleEditFitmentConditions={handleEditFitmentConditions}
            />
          )}
        </Wrapper>
      </Grow>
    )
  }
)

const Wrapper = styled.div`
  flex-grow: 1;
  @media screen and (width <= ${breakpoints.tabletMedium}px) {
    flex-direction: column;
    align-items: baseline;
  }
`

const LeftSide = styled.div`
  @media screen and (width < ${breakpoints.tabletMedium}px) {
    flex-direction: column;
    align-items: baseline;
  }
`

const DetailsInfoAction = styled.span`
  cursor: pointer;
  display: flex;
  gap: 4px;
  margin: auto 0px;
`

const LaborLookupLink = styled(DetailsInfoAction)`
  @media screen and (width <= ${breakpoints.mobileXLarge}px) {
    width: 50%; // Force flex row wrap at this breakpoint
  }
`

const DetailsInfo = styled.div`
  display: flex;
  gap: 24px;
  justify-content: baseline;
  color: ${defaultTheme.colors.link};
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  @media screen and (${breakpoints.mobileMedium}px < width < ${breakpoints.tabletMedium}px) {
    width: 100%;
    justify-content: space-between;
    margin: 0px 0px 16px 0px;
    flex-wrap: wrap;
    height: fit-content;
  }

  @media screen and (width <= ${breakpoints.mobileMedium}px) {
    flex-direction: column;
    height: fit-content;
    gap: 20px;
    margin: 20px 0px;
  }
`

const RightSide = styled.div`
  display: flex;
  gap: 32px;
  @media screen and (width < ${breakpoints.tabletMedium}px) {
    display: none;
  }
`

const MobileActionButtons = styled.div`
  display: none;
  gap: 8px;
  width: 100%;
  @media screen and (width < ${breakpoints.tabletMedium}px) {
    display: flex;
  }

  @media screen and (width <= ${breakpoints.mobileMedium + 25}px) {
    flex-direction: column;
  }
`

const Row = styled.div`
  display: flex;
`

const SpaceBetweenRow = styled(Row)`
  justify-content: space-between;
`

const MobileEditButton = styled.div`
  width: 100%;
`
const MobileDeleteButton = styled.div`
  width: 100%;
`

const VehicleInfo = styled.div`
  width: 100%;
`

const Title = styled.span`
  display: flex;
  font-family: Oswald, serif;
  font-weight: bold;
  font-size: 24px;
`

const SubTitle = styled.span`
  display: flex;
  gap: 16px;
  margin: 8px 0px 16px 0px;
  flex-wrap: wrap;
  @media screen and (width <= ${breakpoints.mobileXLarge}px) {
    flex-direction: column;
  }
`

const VehicleDetailLabel = styled.span`
  align-self: flex-end;
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: bold;
  margin-right: 4px;
`

const VehicleDetail = styled.span`
  align-self: flex-end;
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: normal;
`
const VehicleDetailLink = styled.button`
  align-self: flex-end;
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: normal;
  background: none;
  border: none;
  color: ${defaultTheme.colors.blueLight};
`

const EditButton = styled.div`
  display: flex;
  align-items: center;
  font-family: Oswald, serif;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: ${breakpoints.tabletMedium}px) {
    width: 100;
  }
`

const EditFitmentConditions = styled.div`
  align-self: flex-start;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: ${defaultTheme.colors.blueLight};
  cursor: pointer;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
const EditIcon = styled.div``
const DeleteIcon = styled.div``

const StyledEditIcon = styled(Edit)`
  margin-top: -5px;
  padding-right: 5px;
`
const StyledSpinner = styled(Spinner)`
  margin-right: 5px;
`
