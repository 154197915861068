import {
  CategoriesResponse,
  GroupsResponse,
  IdGroupsResponse,
  IdsEnginesResponse,
  IPartItemResponse,
  JobsResponse,
  JobTerminologiesResponse,
} from 'src/features/partsCatalog/Selections/interfaces'
import { CatalogNode } from '../CatalogNode'
import { CatalogAPI } from './ApiInterface'
import { CatalogTreeParser } from '../CatalogTreeParser'
import { IdValueGeneric } from 'src/store/models/KeyValuePair'
import { Vehicle } from 'src/store/models/Vehicles'
import CatalogServiceProvider from 'src/services/CatalogServiceProvider'
import { StoreInstances } from 'src/store/StoreInstancesContainer'

export class JobsCatalogAPI implements CatalogAPI {
  SESSION_STORAGE_KEY

  constructor() {
    this.SESSION_STORAGE_KEY = 'selected-jobs-catalog-tree'
  }

  fetchCategories = async (): Promise<CategoriesResponse> => {
    const categories = await CatalogServiceProvider.fetchCategories(
      StoreInstances.searchStore.currentVehicle
    )
    return { categories, selectedCategories: [] }
  }

  parseJobsResponseIntoIdGroupsItem = (
    resp: JobsResponse
  ): IdGroupsResponse => {
    const data = resp.map((item) => {
      return {
        id: item.categoryId,
        value: item.categoryName,
        items: item.items.map((i) => {
          return {
            id: i.jobId,
            value: i.jobName,
          }
        }),
      }
    })
    return data
  }

  parsePartTypesJobsResponseIntoIdGroupsItem = (
    resp: JobTerminologiesResponse
  ): IdGroupsResponse => {
    const data = resp.map((item) => {
      return {
        id: item.jobId,
        value: item.jobName,
        items: item.terminologies.map((i) => {
          return {
            id: i.id,
            value: i.value,
          }
        }),
      }
    })
    return data
  }

  fetchGroups = async (
    categories: Array<IdValueGeneric<string, string>>
  ): Promise<GroupsResponse> => {
    try {
      const resp = await CatalogServiceProvider.fetchJobs(categories)
      const groups = this.parseJobsResponseIntoIdGroupsItem(resp)
      return { groups, selectedGroups: [] }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    return null
  }

  fetchPartTypes = async (
    _groups: Array<IdValueGeneric<string, string>>,
    _vehicle: Vehicle,
    catalogNode: CatalogNode[]
  ): Promise<IPartItemResponse | IdsEnginesResponse> => {
    try {
      const resp =
        await CatalogServiceProvider.fetchJobTerminologies(catalogNode)
      const data = this.parsePartTypesJobsResponseIntoIdGroupsItem(resp)
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log(data)
      return data
    } catch (error) {
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log(error)
    }
    return []
  }

  retrieveSelectionsFromSession = (parser: CatalogTreeParser): CatalogNode => {
    const savedJson = sessionStorage.getItem(this.SESSION_STORAGE_KEY)
    if (savedJson?.length > 3) {
      return parser.parse(savedJson || '')
    }
    return undefined
  }

  storeSelectionsOnSession = (selections: string): void => {
    sessionStorage.setItem(this.SESSION_STORAGE_KEY, selections)
  }

  isGfxSupported = (): boolean => {
    return false
  }
}
