import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { LanguageContextProps, LanguageContext } from './LanguageContext'

export const LanguageContextStore = observer(
  ({ children, userStoreRef }: LanguageContextProps): ReactElement => {
    const value = {
      selectedLanguage: userStoreRef?.preferences?.language ?? 'en_US',
    }

    return (
      <LanguageContext.Provider value={value}>
        {children}
      </LanguageContext.Provider>
    )
  }
)
