import { observer } from 'mobx-react-lite'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { RadioGroup, SimpleModal } from 'src/ui-components'
import { useSearchStore } from 'src/store/search/SearchStore'
import { Grid } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { create as createNiceModal, useModal } from '@ebay/nice-modal-react'

export const ProductEngineSelector = createNiceModal(
  observer((): ReactElement => {
    const {
      currentVehicle,
      engineOptionsForProducts,
      updateCurrentVehicle,
      searchBySaytCategories,
      cgts,
    } = useSearchStore()
    const history = useHistory()
    const modal = useModal()

    const [selectedEngine, setSelectedEngine] = useState(undefined)

    useEffect(() => {
      if (selectedEngine !== undefined || engineOptionsForProducts?.length <= 0)
        return
      setSelectedEngine(engineOptionsForProducts[0])
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [engineOptionsForProducts])

    const onSelectedEngine = (selectedEngine) => {
      updateCurrentVehicle({
        ...currentVehicle,
        engine: selectedEngine,
      })
      searchBySaytCategories(cgts, 0)
      history.push('/searchResults')
    }

    const handleOnClose = useCallback(() => {
      modal.remove()
    }, [modal])

    return (
      <SimpleModal
        title="selectVehicleEngine"
        primaryActionText="next"
        secondaryActionText="cancel"
        onPrimaryAction={() => {
          onSelectedEngine(selectedEngine)
          handleOnClose()
        }}
        onSecondaryAction={() => {
          handleOnClose()
        }}
        onCancel={() => {
          handleOnClose()
        }}
        show={modal.visible}
      >
        <Grid container className="py-4 mx-0">
          <Grid
            item
            xs={12}
            className="my-4 mt-0"
            style={{ fontWeight: 700, fontSize: '14px' }}
          >
            {`${currentVehicle.year?.value} ${currentVehicle.make?.value} ${currentVehicle.model?.value}  `}
          </Grid>
          <Grid item>
            <RadioGroup
              id="selectEngineRadioGroup"
              name="chooseVehicleSystem"
              options={engineOptionsForProducts}
              onChange={(selected) => {
                setSelectedEngine(selected)
              }}
              initialValue={selectedEngine?.id}
            />
          </Grid>
        </Grid>
      </SimpleModal>
    )
  })
)
