import { ReactElement } from 'react'
import styled from 'styled-components'
import {
  CheckCircle as CheckCircleIcon,
  Store as StoreIcon,
  Block as BlockIcon,
  Warning,
} from '@mui/icons-material'
import { Button } from 'src/ui-components'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { defaultTheme } from 'src/theme'
import { ProductLocationModel } from 'src/store/models/ProductModel'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'
import { I18n } from 'src/i18n'
import { StoreInstances } from 'src/store/StoreInstancesContainer'

interface StoreInfoCardProps {
  location: ProductLocationModel
  selected?: boolean
}

export const StoreInfoCard = (props: StoreInfoCardProps): ReactElement => {
  const { location, selected } = props
  const { disableStores, getDisableStore } = useShoppingCart()

  const uiStore = useUiStateStore()
  let disableStore = disableStores && getDisableStore(location?.qtyAvailable)
  if (location?.qtyAvailable === 0 && location?.locType !== 'primary') {
    disableStore = true
  }
  if (location.locType === 'viewOnly') disableStore = true

  const handleSelectStore = (selectedLocation: ProductLocationModel) => {
    if (uiStore.locationModalData) {
      uiStore.locationModalData.onChangeLocation(selectedLocation)
    }
    // uiStore.displaySuccessNotification(locationChangedMsg)
    uiStore.closeLocationSelector()
  }

  return (
    <StoreWrapper>
      <div>
        <StoreName>{location.called}</StoreName>
        {!disableStore && (
          <div>
            <StoreInfoBoldText>
              {location.qtyAvailable > 0 && <StyledCheckCircleIcon />}
              {location.qtyAvailable === 0 && <StyledBlockIcon />}
              {location.qtyAvailable} <I18n name="available" />
            </StoreInfoBoldText>
            <StoreScheduleText>{location.schedule}</StoreScheduleText>
          </div>
        )}
        {disableStore && !selected && (
          <WarningWrapper>
            {location.qtyAvailable > 0 && <WarningIcon />}
            {location.qtyAvailable === 0 && <WarningNoQuantity />}
            <div>
              <StoreInfoBoldText>
                {location.qtyAvailable} <I18n name="available" />
              </StoreInfoBoldText>
              {location.qtyAvailable > 0 && (
                <LocationWarning>
                  <I18n name="selectAnotherLocation" />
                </LocationWarning>
              )}
            </div>
          </WarningWrapper>
        )}
        {disableStore && selected && (
          <StoreInfoBoldText>
            {location.qtyAvailable > 0 && <StyledCheckCircleIcon />}
            {location.qtyAvailable} <I18n name="available" />
          </StoreInfoBoldText>
        )}
        {location.order && (
          <FlexDiv>
            <StoreInfoBoldText>
              <I18n name="order" />:
            </StoreInfoBoldText>
            {location.order}
          </FlexDiv>
        )}
        {location.estimatedDelivery && (
          <FlexDiv>
            <StoreInfoBoldText>
              <I18n name="estimatedDelivery" />:
            </StoreInfoBoldText>
            {location.estimatedDelivery}
          </FlexDiv>
        )}
        {location?.locType === 'primary' &&
          StoreInstances.uiStore.isDisplayMultiLocation() && (
            <FlexDiv>
              <StyledStoreIcon />
              <SpanLabel>
                <I18n name="myStore"></I18n>
              </SpanLabel>
            </FlexDiv>
          )}
      </div>
      <div>
        <FlexColumnDiv>
          {selected && <DisableButton text="current" size="small" disabled />}
          {!disableStore && !selected && (
            <Button
              text="select"
              size="small"
              onClick={() => handleSelectStore(location)}
            />
          )}
          {disableStore && !selected && location.locType === 'viewOnly' && (
            <DisableButton text="viewOnly" size="small" disabled />
          )}
          {disableStore && !selected && location.locType !== 'viewOnly' && (
            <DisableButton text="select" size="small" disabled />
          )}
          {location?.locType === 'primary' && (
            <FlexDiv>
              <StyledStoreIcon />
              <SpanLabel>
                <I18n name="myStore"></I18n>
              </SpanLabel>
            </FlexDiv>
          )}
        </FlexColumnDiv>
      </div>
    </StoreWrapper>
  )
}

StoreInfoCard.defaultProps = {
  selected: false,
}

const StyledStoreIcon = styled(StoreIcon)`
  color: #7ebd34;
`

const StyledBlockIcon = styled(BlockIcon)`
  color: #d9001b;
  margin-top: -5px;
  padding-right: 5px;
`

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: #7ebd34;
  margin-top: -5px;
  padding-right: 5px;
`

const FlexDiv = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
`

const SpanLabel = styled.div`
  padding-left: 0.3rem;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #050505;
`

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const StoreInfoBoldText = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #050505;
  padding-right: 5px;
`

const StoreName = styled.div`
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #050505;
`

const StoreScheduleText = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-size: 15px;
  line-height: 30px;
  color: ${defaultTheme.colors.almostBlack};
`

const StoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid #d3d2cf;
  text-align: start;
`

const WarningWrapper = styled.div`
  display: flex;
`
const LocationWarning = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.text};
`
const WarningIcon = styled(Warning)`
  color: ${(p) => p.theme.colors.primary};
  width: 22px;
  height: 18.21px;
  margin-top: 1px;
  margin-right: 3px;
`

const WarningNoQuantity = styled(StyledBlockIcon)`
  padding: 0;
  margin: 0;
  margin-right: 3px;
`
const DisableButton = styled(Button)`
  cursor: auto;
`
