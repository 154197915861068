import UserActionsServiceProvider from 'src/services/UserActionsServiceProvider'
import {
  IParts,
  IUserActions,
  VehicleWithParts,
} from 'src/store/models/IUserActions'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { UserAttributeKey } from 'src/store/user/interfaces'

export const CART_SESSION_KEY = 'cartSession'

export enum CartActionType {
  cartDelete = 'CartDelete',
  cartItemDelete = 'CartItemDelete',
}

export enum QuoteActionType {
  quoteDelete = 'QuoteDelete',
  quoteReCheck = 'QuoteReCheck',
}

export const makeUserActivityReqBody = (
  actionType: CartActionType | QuoteActionType,
  parts: IParts[],
  quoteId?: string
): IUserActions => {
  const cartSession = localStorage.getItem(CART_SESSION_KEY)
    ? localStorage.getItem(CART_SESSION_KEY)
    : ''
  const address = StoreInstances.cart.addresses?.find(
    (a) => a.addressType === 'Primary'
  )

  return {
    user: {
      userId: Number(StoreInstances.userStore.preferences.userId),
      orgId: Number(StoreInstances.userStore.preferences.orgId),
      partDomain: StoreInstances.userStore.getUserAttribute(
        UserAttributeKey.partDomain
      ),
      screenName: StoreInstances.userStore.preferences.screenName,
      wdorgId: StoreInstances.userStore.preferences.wdorgId,
      wdOrgName: StoreInstances.userStore.preferences.wdOrgName,
      orgName: StoreInstances.userStore.preferences.orgName,
    },
    cartSession,
    action: actionType,
    quoteId,
    parts,
    address,
  }
}

const getSearchTypes = (value: number) => {
  switch (value) {
    case 0:
      return 'None'
    case 1:
      return 'YMME'
    case 2:
      return 'GFX'
    case 3:
      return 'Search Results'
    case 4:
      return 'Interchange'
    case 5:
      return 'SAYT'
    default:
      return 'None'
  }
}

export const buildPartsWithVehicleInfo = (
  vehicleWithParts: VehicleWithParts
): IParts[] => {
  return vehicleWithParts.parts.map((p) => {
    const { parts, ...vehicleInfo } = vehicleWithParts
    return {
      ...p,
      vehicle: vehicleInfo,
      quoteId: StoreInstances.cart.getVehicleQuoteId(vehicleWithParts),
      lookup: '',
      lookupType: getSearchTypes(
        StoreInstances.searchStore.lastNonCachedSearch
      ),
      multiseller: false,
      coverageKey: '',
      poNumber:
        StoreInstances.cart.getVehicleFormData(vehicleWithParts).poNumber, // TODO
    }
  })
}

export const trackUserAction = async (
  actionType: CartActionType | QuoteActionType,
  parts: IParts[],
  quoteId?: string
): Promise<void> => {
  const reqBody = makeUserActivityReqBody(actionType, parts, quoteId)
  await UserActionsServiceProvider.sendUserActions(reqBody)
}
