import { createContext, ReactNode } from 'react'
import { UserStore } from 'src/store/user/UserStore'
import { LanguageContextModel } from '../models/LanguageContextModel'

const initialContext = {
  selectedLanguage: 'en_US',
}

export const LanguageContext =
  createContext<LanguageContextModel>(initialContext)

LanguageContext.displayName = 'Language'

export interface LanguageContextProps {
  children: ReactNode
  userStoreRef: UserStore
}
