import { LocationType } from 'src/helpers/locationType'
import {
  AvailabilityErrorType,
  ProductLocationModel,
  ProductModel,
} from '../models/ProductModel'
import {
  CartVehicle,
  OrderLocation,
  OrderSelection,
  ShoppingCartProduct,
} from '../models/ShoppingCartModels'
import {
  OrderLabourInfo,
  OrderPart,
  SpecsOrderInfo,
} from 'src/api/cart/interfaces'
import { StoreInstances } from '../StoreInstancesContainer'
import { Optional } from 'src/common-interfaces/generic-interfaces'
import { Vehicle } from '../models/Vehicles'
import { LaborItem } from '../models/LaborModel'
import { VehicleSpecificationCartItem } from '../models/VehicleSpecification'
import { LocationInfoResponse, PartInfoResponse } from './ValidationInterfaces'
import { buildAvailabilityRequest, fetchProduct } from './CartValidations'
import { chunk, flatten } from 'lodash-es'
import PartServiceProvider from 'src/services/PartServiceProvider'
import { IAddToCartNotification } from '../quote/interfaces'
import { Link } from 'src/ui-components'
import { ISetQtyProps } from './ShoppingCart'
import { ToastNotificationVariant } from 'src/ui-components/util/Interfaces'
import { SELL_NETWORK_BATCH_SIZE } from 'src/config/constants'
// import { Translate2 } from 'src/i18n/translate2'

export const selectLocationByQty = (
  product: ProductModel,
  qtyRequested: number,
  inCart = false
): ProductLocationModel => {
  const locations = product.location ?? []

  const locationsCopy = [...locations]
  const match = locationsCopy.find((location) => {
    return inCart
      ? location.qtyAvailable >= qtyRequested &&
          location.locType !== LocationType.VIEW_ONLY &&
          location.isSelected
      : location.qtyAvailable >= qtyRequested && location.isSelected
  })
  if (match) {
    return match
  }
  /* Here you can see how we're setting the primary
  location as default if the user doesn't have any available locations
  ALS(II.a4)
  */
  return locations[0]
}

export const currentLocationHasQuantity = (
  product: ProductModel,
  qtyRequested: number,
  locationId: string
): boolean => {
  const locations = product.location ?? []

  const selectedLocation = locations.find(
    (item) => item.locationId.toString() === locationId.toString()
  )
  return selectedLocation && selectedLocation.qtyAvailable
    ? selectedLocation.qtyAvailable >= qtyRequested
    : false
}

export const makeNewCartVehicle = (vehicle: Vehicle): CartVehicle => {
  return {
    vehicle,
    products: [],
    specifications: [],
    laborResults: [],
    orderFormData: {
      poNumber: '',
      customerName: '',
      noteToStore: '',
      personalNote: '',
      shipToAddress: '',
    },
    locations: [],
    purolator: {
      loadingEstimates: false,
      operation: { operationType: undefined, product: undefined },
    },
  }
}

export const getGoogleAnalyticsCategory = (
  allianceTerminologyID: string,
  partDescription: string
): string => {
  // Mapping category to part type description to align with B2C model
  const selectedPartTypeValue =
    StoreInstances.searchStore.findSelectedPartTypeById(
      allianceTerminologyID
    )?.value

  return selectedPartTypeValue || partDescription // Using part description as backup MP4P-1212
}

export const isBuyDirectLocation = (
  location: ProductLocationModel
): boolean => {
  return (
    location?.locType === LocationType.BUY_DIRECT ||
    location?.locType === LocationType.VIRTUAL_BUY_DIRECT
  )
}

export const getLocationById = (
  product: ProductModel,
  locationId: string
): Optional<ProductLocationModel> => {
  return (product.location || []).find(
    (location) => location?.locationId?.toString() === locationId?.toString()
  )
}

export const mapCartProductsWithManyLocations = (
  cartProducts: Array<ShoppingCartProduct>,
  cartLocations: OrderLocation[]
): Array<OrderPart> => {
  const mapped: OrderPart[] = []
  let count = 0
  cartProducts.forEach((cartPart: ShoppingCartProduct) => {
    cartPart.orderSelections.forEach((locationSelection: OrderSelection) => {
      const packNumUnits100 = cartPart.location.find(
        (loc) => loc.locationId.toString() === '100'
      ).packNumUnits
      count += 1
      const { locationId, quantityRequested } = locationSelection
      // const locationIdForOrderTypeTransport = isBuyDirectLocation(location)
      // ? locationId
      // : '100'
      let cartLocation = cartLocations.find(
        (location) => Number(location.locationId) === Number(locationId)
      )
      /**
       * We are creating cartLocations in the cartVehicle object for each BuyDirectLocation, Virtual Buy Direct Location and Primary Location. We are not creating
       * them for any other location i.e., alternate locations, view only locations, virtual location. We should set the
       * the order type and transport type for these 3 types of locations to primary location(100). Hence the below logic.
       */
      if (!cartLocation) {
        cartLocation = cartLocations.find(
          (location) => Number(location.locationId) === 100
        )
      }
      const { orderType, transportId } = cartLocation
      const location = getLocationById(cartPart, locationId)
      const category = getGoogleAnalyticsCategory(
        cartPart?.allianceterminologyId ?? '',
        cartPart?.description ?? ''
      )

      const line: OrderPart = {
        allianceProductId: cartPart.allianceProductId,
        // MP4P-1212 - Sending the category description instead of the alliance terminology ID
        // TODO: ask the API side to rename this field to GACategory (for the order endpoint only)
        allianceTerminologyId: category,
        brand: cartPart.brand,
        description: cartPart.description,
        lineNo: count,
        lineCode: cartPart.lineCode,
        locationDescription: location.called,
        locationId,
        partNumber: cartPart.partNumber,
        manufacturerName: cartPart.manufacturerName,
        isPriceOverride: false,
        priceOverrideMsg: '',
        quantityRequested,
        quantityAvailable: location.qtyAvailable,
        unitCorePrice: location.coreCost,
        unitCostPrice: location.cost,
        unitListCore: location.coreList,
        unitListPrice: location.list,
        unitOfMeasure: location.unitOfMeasure,
        orderType,
        transportId,
        isBuyDirectLocation: isBuyDirectLocation(location),
        snCalled: '',
        packNumUnits: location.packNumUnits,
        packNumUnits100,
        partOrderNumber: cartPart?.orderNumber ? cartPart.orderNumber : 0,
      }
      mapped.push(line)
    })
  })
  return mapped
}

export const mapCartVehicleLaborsToLaborsInOrderRequestBody = (
  cartLabors: Array<LaborItem>
): Array<OrderLabourInfo> => {
  const labors: Array<OrderLabourInfo> = []
  cartLabors.forEach((cartLabor) => {
    const orderLabor = {
      details: cartLabor.details,
      hours: cartLabor.hours,
      laborId: cartLabor.laborId,
      description: cartLabor.laborDescription,
      rate: cartLabor.rate,
      notes: cartLabor.miscellaneousText,
      skillLevel: cartLabor.skillLevel,
      warrantyHours: cartLabor.warrantyHrs,
    }
    labors.push(orderLabor)
  })
  return labors
}

export const mapCartVehicleSpecsToSpecsInOrderRequestBody = (
  cartSpecifications: Array<VehicleSpecificationCartItem>
): Array<SpecsOrderInfo> => {
  const specifications: Array<SpecsOrderInfo> = []
  cartSpecifications.forEach((cartSpecification) => {
    const orderSpec = {
      ...cartSpecification,
      miscellaneousTexts: cartSpecification.miscellaneousTexts.join(', '),
      id: 0,
      orderId: 0,
    }
    specifications.push(orderSpec)
  })
  return specifications
}

export const isVehicleComplete = (vehicle: Vehicle): boolean => {
  return (
    vehicle &&
    !!vehicle.year?.id &&
    !!vehicle.make?.id &&
    !!vehicle.model?.id &&
    !!vehicle.modelType?.id &&
    !!vehicle.engine?.id
  )
}

export const findOrderSelection = (
  product: ShoppingCartProduct,
  locationId: string
): Optional<OrderSelection> => {
  if (!product?.orderSelections) {
    return undefined
  }
  return product.orderSelections.find(
    (selection: OrderSelection) =>
      selection.locationId.toString() === locationId.toString()
  )
}

export const getActiveLocation = (
  product: ShoppingCartProduct
): ProductLocationModel => {
  if (product.activeLocationId === undefined) {
    return product.location[0]
  }
  return getLocationById(product, product.activeLocationId)
}

/**
 * Products match if the partNumber, lineCode, description, and year all match
 */
export const productsMatch = (p1: ProductModel, p2: ProductModel): boolean => {
  return (
    p1.partNumber === p2.partNumber &&
    p1.lineCode === p2.lineCode &&
    p1.description === p2.description &&
    p1.fromYear === p2.fromYear
  )
}

export const allowOrderWithoutAvailableInventory = (
  location: ProductLocationModel
): boolean => {
  /* This function is used to switch the ALS(II.a)
  part of the flow to ALS(II.a1) or ALS(II.a2), which means,
  detecting if a location change should be overrided based on
  if the user has the user preference findit_orderIfNotAvail and
  the location that he's manually trying to select is the primary
  location of a product */
  return (
    StoreInstances.userStore?.preferences?.findit_orderIfNotAvail === 'true' &&
    location.locType === LocationType.PRIMARY
  )
}

export const updateLocationPrices = (
  oldLocation: ProductLocationModel,
  newLocation: LocationInfoResponse
): ProductLocationModel => {
  const newCost = newLocation?.unitCostPrice ?? 0
  const newCoreCost = newLocation?.unitCorePrice ?? 0
  const newCoreList = newLocation?.unitListCore ?? 0
  const newList = newLocation?.unitListPrice ?? 0

  if (!newLocation) {
    return oldLocation
  }

  return {
    ...oldLocation,
    cost: newCost,
    coreCost: newCoreCost,
    coreList: newCoreList,
    list: newList,
    isSelected: newLocation?.isSelected || false,
    priceBreaks:
      (newLocation?.priceBreaks || [])?.length > 0
        ? newLocation?.priceBreaks
        : oldLocation.priceBreaks,
  }
}

export const mapLocationInfo = (
  location: LocationInfoResponse
): ProductLocationModel => {
  return {
    isSelected: location.isSelected,
    locationId: location.locationId,
    called: location.locationDescription,
    cost: location.unitCostPrice ?? 0,
    coreCost: location.unitCorePrice ?? 0,
    coreList: location.unitListCore ?? 0,
    list: location.unitListPrice ?? 0,
    qtyAvailable: location.quantityAvailable ?? 0,
    locType:
      location.locationId === '100'
        ? LocationType.PRIMARY
        : location.locationStatus,
    priceBreaks: location.priceBreaks || [],
  }
}

export const roundQty = (currentQty: number, buyIncrement: number): number => {
  return Math.ceil(currentQty / buyIncrement) * buyIncrement
}

export const updateLocationInfo = (
  oldPart: ProductModel,
  newPart: PartInfoResponse
): ProductModel => {
  /**
   * In integrations, with local inventory, the host app can add new locations to the part.
   * We don't need to consider them after any change to the quantity.(Same behaviour as AES V2)
   * Removing the host locations.
   * TODO: See if we should rely only on the locations in the new part to avoid any issues.
   */
  const filteredLocations = oldPart.location.filter(
    (location) => Number(location.locationId) >= 100
  )

  const updatedLocations = filteredLocations.map(
    (location): ProductLocationModel => {
      const updatedLocationInfo: LocationInfoResponse = newPart.locations.find(
        (l) => Number(l.locationId) === Number(location.locationId)
      )
      const updatedLocation = updateLocationPrices(
        location,
        updatedLocationInfo
      )
      // if (
      //   location.cost !== updatedLocation.cost &&
      //   updatedLocation.isSelected
      // ) {
      //   StoreInstances.uiStore.displaySuccessNotification(
      //     Translate2('costChangedFrom', [location?.cost, updatedLocation?.cost])
      //   )
      // }
      updatedLocation.locType =
        updatedLocationInfo?.locationId === '100' &&
        updatedLocationInfo?.locationStatus !== LocationType.VIEW_ONLY
          ? LocationType.PRIMARY
          : updatedLocation.locType

      updatedLocation.qtyAvailable = updatedLocationInfo?.quantityAvailable ?? 0

      updatedLocation.minQty = updatedLocationInfo?.minOrderQty ?? 1
      return updatedLocation
    }
  )

  // Add any new locations to the existing locations.
  newPart.locations.forEach((location) => {
    if (
      updatedLocations.filter(
        (l) => l.locationId.toString() === location.locationId.toString()
      ).length < 1
    )
      updatedLocations.push(mapLocationInfo(location))
  })

  /**
   * When coming from addQuoteToCart we are not having primary location at
   * updatedLocations[0]. Hence we are rearranging the updatedLocations as below to have
   * primary location at index 0.
   */
  const primaryLocationIndex = updatedLocations.findIndex(
    (location) => location.locationId.toString() === '100'
  )
  const primaryLocation = updatedLocations[primaryLocationIndex]
  updatedLocations.splice(primaryLocationIndex, 1)
  updatedLocations.unshift(primaryLocation)
  oldPart.weight = newPart.weight
  oldPart.height = newPart.height
  oldPart.width = newPart.width
  oldPart.length = newPart.length
  return { ...oldPart, location: updatedLocations }
}

export const updatePartInfo = async (
  oldPart: ProductModel,
  requestedQty: number,
  location: ProductLocationModel
): Promise<ProductModel> => {
  const reqData = buildAvailabilityRequest(oldPart, requestedQty, location)
  const updatedPartInfo = await fetchProduct({
    parts: [reqData],
  })
  return updateLocationInfo(oldPart, updatedPartInfo)
}

export const getUpdatedPartsList = async (
  productDetails: ISetQtyProps[]
): Promise<ProductModel[]> => {
  // The sellnetworks are limited by the number of records they can process at a time.
  // So we need to chunk the parts before checking the availability.
  const chunks = chunk(productDetails, SELL_NETWORK_BATCH_SIZE)
  return Promise.all(
    chunks.map((partsChunk) => {
      return PartServiceProvider.getAvailability({
        parts: partsChunk.map(({ product, quantity, locationId }) => {
          const originalLocation = getLocationById(product, locationId)
          return buildAvailabilityRequest(product, quantity, originalLocation)
        }),
      })
    })
  ).then((updatedPartChunks) => {
    return flatten(
      updatedPartChunks.map((updatedChunk, index) => {
        const currentChunk = chunks[index]
        return currentChunk.map((currentPart, idx) => {
          return updateLocationInfo(currentPart.product, updatedChunk[idx])
        })
      })
    )
  })
}

/**
 * Util used to compare a list of parts with the parts added to a cart and display a notification based on what was added to the cart.
 * @param products The list of products being compared.
 * @param shoppingCartVehicle The vehicle being targetted.
 * @param laborResults The array of labour results being compared with the cart.
 * @param specifications The array of specifications being compared with the cart.
 * @param viewCartLink The object to add link in the toast notification.
 */
export const showCartPartComparisionNotification = (
  products: ShoppingCartProduct[],
  shoppingCartVehicle: CartVehicle,
  laborResults: LaborItem[],
  specifications: VehicleSpecificationCartItem[],
  viewCartLink: Link
): void => {
  const addToCartNotification: IAddToCartNotification = {
    toastNotificationType: ToastNotificationVariant.WARNING,
    messages: [],
  }

  const cartProducts = products
    .map((p) =>
      shoppingCartVehicle.products.find(
        (cartProduct) =>
          cartProduct.partNumber === p.partNumber &&
          cartProduct.lineCode === p.lineCode
      )
    )
    .filter((p) => p) // removing undefined items from previous map

  const productsWithErrors = cartProducts?.filter(
    (p) => p?.availabilityErrors?.length
  )

  const cartHiddenProducts = productsWithErrors?.filter((p) =>
    p.availabilityErrors?.find(
      (e) =>
        e.errorType === AvailabilityErrorType.CANT_BE_FULFILLED ||
        e.errorType === AvailabilityErrorType.MIN_QTY_CHANGE
    )
  )

  if (!cartProducts || cartHiddenProducts?.length === cartProducts?.length) {
    if (
      laborResults &&
      specifications &&
      laborResults.length &&
      specifications.length
    ) {
      addToCartNotification.messages.push('onlySpecsLaborResultsAreAddedToCart')
    } else if (laborResults && laborResults.length) {
      addToCartNotification.messages.push('onlyLaborResultsAreAddedToCart')
    } else if (specifications && specifications.length) {
      addToCartNotification.messages.push('onlySpecsAreAddedToCart')
    } else {
      addToCartNotification.toastNotificationType =
        ToastNotificationVariant.ERROR
      addToCartNotification.messages.push('noPartsFoundAddedToCart')
    }
  } else if (productsWithErrors?.length) {
    addToCartNotification.messages.push('issueInAddingPartsToCart')
  } else {
    addToCartNotification.toastNotificationType =
      ToastNotificationVariant.SUCCESS
    addToCartNotification.messages.push('productsWereAddedToTheCart')
  }
  switch (addToCartNotification.toastNotificationType) {
    case ToastNotificationVariant.SUCCESS:
      StoreInstances.uiStore.displaySuccessNotification(
        addToCartNotification?.messages?.[0],
        viewCartLink
      )
      break
    case ToastNotificationVariant.ERROR:
      StoreInstances.uiStore.displayErrorNotification(
        addToCartNotification?.messages?.[0]
      )
      break
    case ToastNotificationVariant.WARNING:
      for (const eachMessage of addToCartNotification.messages) {
        StoreInstances.uiStore.displayWarningNotification(
          eachMessage,
          viewCartLink
        )
      }
      break
    default:
      break
  }
}

const isFindItOffered = (): boolean => {
  const prefs = StoreInstances.userStore?.preferences
  if (!prefs) {
    return false
  }
  return (
    prefs.cart_locPartMsg === 'true' && prefs.findit_orderIfNotAvail === 'true'
  )
}

export const isLocatePartMsg = (orderPart: OrderPart): boolean => {
  if (isFindItOffered()) {
    if (orderPart.locationId?.toString() === '100') {
      if (orderPart.quantityAvailable < orderPart.quantityRequested) {
        return true
      }
    }
  }
  return false
}
