import { Add, Remove } from '@mui/icons-material'
import { debounce } from 'lodash-es'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Translate } from 'src/i18n'
import { roundQty } from 'src/store/cart/Utils'
import { useBannerPromotionStore } from 'src/store/promotion/BannerPromotionStore'
import { timeout } from 'src/theme/timeout'
import styled from 'styled-components'
import { Tooltip } from '../modals/Tooltip'
import { ProductModel } from 'src/store/models/ProductModel'
import { Vehicle } from 'src/store/models/Vehicles'
import { useCartProduct } from 'src/hooks/useCartProduct'
import { StoreInstances } from 'src/store/StoreInstancesContainer'

interface QuantitySelectorProps {
  initialValue?: number
  orderIfNotAvailable: boolean
  minQty?: number
  maxQty?: number
  stepSize?: number
  onChange: (value: number) => Promise<void>
  onRemove?: () => void
  onLocationChangeTooltip?: boolean
  locationChangeTooltipContent?: React.ReactElement
  locationScheduleContent?: string
  orderNumber?: number
  isStoked?: boolean
  cursorType?: string
  disabled?: boolean
  isCartItem?: boolean
  value: string
  setValue: (val: string) => void
  isCondensedView?: boolean
  product: ProductModel
  vehicle?: Vehicle
  isInMulticationDrawer?: boolean
}

const ABSOLUTE_MAX_QUANTITY = 999

export const QuantitySelector = (
  props: QuantitySelectorProps
): ReactElement => {
  const {
    onChange,
    minQty = 0,
    maxQty = ABSOLUTE_MAX_QUANTITY,
    stepSize = 1,
    onRemove,
    orderIfNotAvailable,
    onLocationChangeTooltip,
    locationChangeTooltipContent,
    locationScheduleContent,
    orderNumber,
    isStoked,
    cursorType = 'pointer',
    disabled = false,
    isCartItem,
    value,
    setValue,
    isCondensedView,
    product,
    vehicle,
    isInMulticationDrawer = false,
  } = props

  const cartProduct = useCartProduct({
    product,
    vehicle,
  })

  const { inCart, cartProductsData, showLocationSelector } = cartProduct

  const [lastChangedValue, setlastChangedValue] = useState<string>(value)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const loading = cursorType !== 'pointer'

  const handlePromotionBanner = () => {
    if (timeOutPromoDrawer) {
      clearInterval(timeOutPromoDrawer)
    }
    if (timeOutPromoDrawer) {
      if (timeOutPromoDrawerCart) {
        clearInterval(timeOutPromoDrawerCart)
      }
    }
    if (orderNumber) {
      handleCoverageMatch(orderNumber)
    }
    handleBannerPromoDrawerCartOpenExpanded()
  }

  const {
    handleCoverageMatch,
    timeOutPromoDrawer,
    timeOutPromoDrawerCart,
    handleBannerPromoDrawerCartOpenExpanded,
  } = useBannerPromotionStore()

  const minusStep = async () => {
    function checkIfMinusStepIsLeadingToMinQty(val: string) {
      return Number(val) - 1 === minQty
    }
    handlePromotionBanner()
    const updatedVal = checkIfMinusStepIsLeadingToMinQty(value)
      ? minQty
      : Number(value) - stepSize
    if (updatedVal < minQty) {
      setShowTooltip(true)
      setTimeout(() => {
        setShowTooltip(false)
      }, timeout.minQtyNotification)
      if (onRemove) {
        onRemove()
      }
      return
    }
    setValue(`${updatedVal}`)
    await onChange(updatedVal)
  }

  const plusStep = async () => {
    const updatedVal = Number(value) + stepSize
    handlePromotionBanner()
    if (
      (updatedVal > maxQty && !orderIfNotAvailable) ||
      updatedVal > ABSOLUTE_MAX_QUANTITY
    ) {
      return
    }
    setValue(`${updatedVal}`)
    await onChange(updatedVal)
  }

  const enforceRange = async (v: string) => {
    if (!isValidInt(v)) {
      setValue(`${lastChangedValue}`)
      return
    }
    let numericValue = Number.parseInt(v, 10)

    if (numericValue < minQty) {
      numericValue = minQty
    }
    if (numericValue > maxQty && !orderIfNotAvailable) {
      numericValue = maxQty
    }
    if (numericValue > ABSOLUTE_MAX_QUANTITY) {
      numericValue = ABSOLUTE_MAX_QUANTITY
    }
    if (Number(v) !== Number(value)) {
      await onChange(numericValue)
      setValue(`${numericValue}`)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRoundFigure = useCallback(
    debounce(async (v: string) => {
      if (!isNaN(+v)) {
        let tempQty = Number(v)
        if (tempQty < minQty) {
          tempQty = minQty
        }
        const roundedValue = roundQty(tempQty, stepSize)
        setValue(roundedValue.toString())
        await onChange(Number(roundedValue))
      }
    }, 800),
    [stepSize, onChange, minQty]
  )

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleTypedInput = async (v: string) => {
    if (v !== '') {
      setlastChangedValue(v)
    }
    if (v === '' || v === undefined) {
      setValue('')
      return
    }

    if (!isValidInt(v)) {
      return
    }
    if (parseInt(v, 10) > ABSOLUTE_MAX_QUANTITY) {
      return
    }
    setValue(v)
    handleRoundFigure(v)
  }

  const atMax = Number(value) === maxQty

  const tooltipMessage =
    minQty !== undefined
      ? Translate('theMinimumOrderQuantityIs', [`${minQty}`])
      : undefined

  const [inHover, setHover] = useState(false)

  useEffect(() => {
    if (locationChangeTooltipContent && isCartItem) {
      setHover(true)
    }
  }, [locationChangeTooltipContent, isCartItem])

  return (
    <Wrapper isCondensedView={isCondensedView}>
      <Tooltip message={tooltipMessage} show={showTooltip}>
        <AddRemoveBtn
          onClick={() => {
            if (!loading && !disabled) {
              if (
                StoreInstances?.uiStore?.isDisplayMultiLocation() &&
                inCart &&
                cartProductsData.length > 1 &&
                !isInMulticationDrawer
              ) {
                showLocationSelector()
                return
              } else {
                minusStep()
              }
            }
          }}
          disabled={disabled}
          cursorType={cursorType}
          isCondensedView={isCondensedView}
        >
          <Remove />
        </AddRemoveBtn>
      </Tooltip>
      <FieldWrapper isStocked={isStoked}>
        <Tooltip message={locationScheduleContent} show={inHover}>
          <Field
            value={Number(value) > 0 ? value : ''}
            onChange={(e) => handleTypedInput(e.target.value)}
            onBlur={(e) => {
              enforceRange(e.target.value)
            }}
            onClick={() => {
              if (
                StoreInstances?.uiStore?.isDisplayMultiLocation() &&
                inCart &&
                cartProductsData.length > 1
              ) {
                showLocationSelector()
              }
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => {
              setHover(false)
            }}
            disabled={loading || disabled}
            isCondensedView={isCondensedView}
          />
        </Tooltip>
      </FieldWrapper>
      <Tooltip
        content={locationChangeTooltipContent}
        show={onLocationChangeTooltip}
      >
        <AddRemoveBtn
          onClick={() => {
            if (!loading && !disabled && !atMax) {
              if (
                StoreInstances?.uiStore?.isDisplayMultiLocation() &&
                inCart &&
                cartProductsData.length > 1 &&
                !isInMulticationDrawer
              ) {
                showLocationSelector()
                return
              }
              plusStep()
            }
          }}
          disabled={atMax || disabled}
          cursorType={cursorType}
          isCondensedView={isCondensedView}
        >
          <Add />
        </AddRemoveBtn>
      </Tooltip>
    </Wrapper>
  )
}

QuantitySelector.displayName = 'QuantitySelector'

QuantitySelector.defaultProps = {
  initialValue: 1,
  minQty: undefined,
  stepSize: 1,
  maxQty: 999,
  onRemove: (): void => undefined,
  onLocationChangeTooltip: false,
  locationChangeTooltipContent: null,
  locationScheduleContent: '',
}

const Wrapper = styled.div<FieldProps>`
  display: flex;
  border: 2px solid ${(p) => p.theme.colors.greyMedium};
  border-radius: 3px;
  min-width: 160px;
  margin: ${(p) => (p.isCondensedView ? '0px 2px' : '0px')};
  width: 168px;
`

interface AddRemoveBtnProps {
  disabled: boolean
  cursorType: string
  isCondensedView?: boolean
}

const AddRemoveBtn = styled.span<AddRemoveBtnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(p) => (p.isCondensedView ? '29px' : '36px')};
  height: ${(p) => (p.isCondensedView ? '29px' : '36px')};
  color: ${(p) =>
    p.disabled ? p.theme.colors.disabledButtonText : p.theme.colors.text};
  background-color: ${(p) => p.theme.colors.greyMedium};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : p.cursorType)};
`
interface FieldProps {
  isCondensedView?: boolean
}

const Field = styled.input<FieldProps>`
  width: 96px;
  height: ${(p) => (p.isCondensedView ? '29px' : '36px')};
  border: none;
  text-align: center;
`

interface IsStockedProp {
  isStocked?: boolean
}

const FieldWrapper = styled.span<IsStockedProp>`
  display: flex;
  flex: auto;
  justify-content: center;
  box-shadow: ${(p) =>
    `0 0 0 ${p.isStocked ? '4px' : '0'} ${p.theme.colors.blueLight}`};
`

const isValidInt = (n: string): boolean => {
  // Doesn't handle starting zeros. But minimum quantity will handle that.
  return /^\d+$/.test(n)
}
