import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { I18n } from 'src/i18n'
import { useLaborLookup } from 'src/store/labor/LaborStore'
import { Checkbox } from 'src/ui-components'
import { LaborItem } from 'src/store/models/LaborModel'
import { defaultTheme } from '../../../theme'
import { getLaborRateFromCurrencyString } from 'src/helpers/utils'
import { Sanitized } from 'src/features/search/Results/utils/Sanitized'

interface LaborResultBodyInterface {
  onLaborResultChange: (laborResults: LaborItem[]) => void
  selectedLaborResults: LaborItem[]
}

const LaborResultBody = observer(
  (props: LaborResultBodyInterface): ReactElement => {
    const { onLaborResultChange, selectedLaborResults } = props
    const {
      results: laborResults,
      laborResultLoading,
      laborRate,
      setLaborRate,
    } = useLaborLookup()

    useEffect(() => {
      if (laborRate !== undefined || laborResults.length <= 0) return

      setLaborRate(laborResults[0]?.laborRate)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [laborRate, laborResults])

    useEffect(() => {
      if (laborRate === undefined || selectedLaborResults.length <= 0) return
      onLaborResultChange(
        selectedLaborResults.map((laborResul) => {
          return {
            ...laborResul,
            laborRate,
            rate: Number(laborRate.replace('$', '')),
          }
        })
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [laborRate])

    const results: LaborItem[] = (laborResults || []).map((result) => {
      const newLaborRate =
        laborRate && laborRate !== '' ? laborRate : result.laborRate
      return {
        ...result,
        laborRate: newLaborRate,
        rate: Number(newLaborRate.replace('$', '')),
      }
    })

    const isResultSelected = (result: LaborItem): boolean => {
      return (
        selectedLaborResults.find((r) => {
          return r.laborId === result.laborId
        }) !== undefined
      )
    }

    const onSelectResult = (result: LaborItem): void => {
      if (isResultSelected(result)) {
        onLaborResultChange(
          selectedLaborResults.filter((r) => r.laborId !== result.laborId)
        )
      } else {
        onLaborResultChange([...selectedLaborResults, { ...result }])
      }
    }

    return (
      <LaborResultBodyContainer>
        {laborResultLoading ? (
          <LoadingWrapper>
            <Spinner animation="border" />
          </LoadingWrapper>
        ) : (
          <>
            <GridHeader container>
              <CheckBoxGrid item xs={1}></CheckBoxGrid>
              <Grid xs={4} item>
                <I18n name="laborOperationsDescriptions" />
              </Grid>
              {/* <Grid xs={3} item>
                <I18n name="laborRatePerHour" />
              </Grid> */}
              <Grid xs={2}>
                <I18n name="TotalCostInHours" />
              </Grid>
              <Grid xs={2} item>
                <I18n name="hours" />
              </Grid>
              <Grid xs={2} item>
                <I18n name="warrantyHours" />
              </Grid>
              <Grid xs={1} item>
                <I18n name="skillLevel" />
              </Grid>
            </GridHeader>
            {results.length > 0 &&
              results.map((result) => {
                return (
                  <DataRowGrid container key={result.laborId}>
                    <CheckBoxGrid item xs={1}>
                      <Box display="flex" justifyContent="flex-end">
                        <div className="px-2">
                          <Checkbox
                            value=""
                            onClick={() => onSelectResult(result)}
                            active={isResultSelected(result)}
                            label=""
                          />
                        </div>
                      </Box>
                    </CheckBoxGrid>
                    <Grid xs={4} item>
                      <DescriptionWrapper>
                        {result.laborDescription}
                      </DescriptionWrapper>
                      {result.miscellaneousText !== '' ? (
                        <MiscellaneousTextWrapper>
                          <Sanitized html={result.miscellaneousText} />
                        </MiscellaneousTextWrapper>
                      ) : null}
                    </Grid>
                    {/* <Grid xs={3} item>
                      {result.laborRate}
                    </Grid>
                 */}
                    <Grid xs={2}>
                      {(
                        Number(
                          getLaborRateFromCurrencyString(result.laborRate)
                        ) * Number(result.hours)
                      ).toFixed(2)}
                    </Grid>
                    <Grid xs={2} item>
                      {result.hours}
                    </Grid>
                    <Grid xs={2} item>
                      {result.warrantyHrs}
                    </Grid>
                    <Grid xs={1} item>
                      {result.skillLevel}
                    </Grid>
                  </DataRowGrid>
                )
              })}
          </>
        )}
      </LaborResultBodyContainer>
    )
  }
)

export default LaborResultBody

const LaborResultBodyContainer = styled(Box)`
  max-width: 1250px;
  margin-left: -32px;
  margin-right: -32px;
`

const GridHeader = styled(Grid)`
  background: ${defaultTheme.colors.rowFeaturedBackground};
  height: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${defaultTheme.colors.columnBorder};
  text-transform: uppercase;
  padding-top: 5px;
  border-top: 1px solid ${defaultTheme.colors.greyBorder};
`

const CheckBoxGrid = styled(Grid)`
  text-align: end;
  padding-right: 10px;
  width: 45px;
  padding-top: 3px;
  padding-left: 10px;
`

const DataRowGrid = styled(Grid)`
  height: auto;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid ${defaultTheme.colors.borderTable};
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 480px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${defaultTheme.colors.secondary};
  opacity: 0.5;
  transition: 0.2s;
`
const DescriptionWrapper = styled.div`
  color: ${defaultTheme.colors.black};
  font-weight: bold;
`

const MiscellaneousTextWrapper = styled.div`
  color: #6b6b6b;
`
