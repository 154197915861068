import { ReactElement, useState } from 'react'
import PartsCatalog from './PartsCatalog'
import { isMobile } from 'react-device-detect'
import { LaborLookupSelectorMobile } from 'src/features/partsCatalog/Selections/LaborLookupSelectorMobile'

interface Props {
  partGroupId?: string
}

const DrawerBody = (props: Props): ReactElement => {
  const { partGroupId } = props
  const [mode, setMode] = useState(0)
  const [, setHideTitle] = useState(false)
  if (isMobile) {
    return (
      <LaborLookupSelectorMobile
        partGroupId={partGroupId}
        {...{ mode, setMode }}
        setHideTitle={(hide) => {
          setHideTitle(hide)
        }}
      />
    )
  } else {
    return <PartsCatalog partGroupId={partGroupId} />
  }
}
export default DrawerBody
