import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { create as createNiceModal, useModal } from '@ebay/nice-modal-react'
import BaseDrawer from 'src/ui-components/modals/BaseDrawer'
import DrawerBody from './DrawerBody'
import DrawerFooter from './DrawerFooter'
import DrawerHeader from './DrawerHeader'
import { breakpoints } from 'src/theme/breakpoints'
import { isMobile } from 'react-device-detect'
import { DrawerSizes } from 'src/theme/drawer-size'
import { Vehicle } from '../../../store/models/Vehicles'
import { ProductModel } from '../../../store/models/ProductModel'

interface LaborLookupDrawerProps {
  vehicle: Vehicle
  product?: ProductModel
  setLaborLookup: (laborLookup: boolean) => void
  setLaborResult: (laborResult: boolean) => void
}

const LaborLookupDrawer = createNiceModal<LaborLookupDrawerProps>(
  observer(
    ({
      vehicle,
      product,
      setLaborLookup,
      setLaborResult,
    }: LaborLookupDrawerProps): ReactElement => {
      const modal = useModal()
      // const { currentVehicle: vehicle } = useSearchStore()

      const yearMakeModel = `${vehicle?.year?.value} ${vehicle?.make?.value} ${vehicle?.model?.value}`
      const engine = vehicle?.engine?.value || ''

      const header = <DrawerHeader {...{ yearMakeModel, engine }} />

      const body = <DrawerBody partGroupId={product?.group?.id} />

      const footer = (
        <DrawerFooter
          setLaborLookup={setLaborLookup}
          setLaborResult={setLaborResult}
        />
      )

      const showDividerLines = {
        showHeaderDivider: true,
        showFooterDivider: true,
      }

      const handleClose = () => {
        modal.remove()
      }

      return (
        <BaseDrawer
          open={modal.visible}
          title={isMobile ? ' ' : 'laborLookup'}
          header={isMobile ? null : header}
          body={body}
          footer={footer}
          onClose={handleClose}
          {...showDividerLines}
          width={window.innerWidth < breakpoints.tabletXLarge ? '100%' : 'auto'}
          height={
            window.innerWidth < breakpoints.tabletXLarge ? '90vh' : '100%'
          }
          minWidth={
            window.innerWidth < breakpoints.tabletXLarge
              ? '100%'
              : DrawerSizes.medium
          }
        />
      )
    }
  )
)

export default LaborLookupDrawer
