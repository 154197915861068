import { LocationType } from 'src/helpers/locationType'
import { Optional } from '../../../../common-interfaces/generic-interfaces'
import { ProductLocationModel } from '../../../../store/models/ProductModel'

export const getStartLocation = (
  locations: Array<ProductLocationModel>,
  minInventory: number,
  inCart = false
): Optional<ProductLocationModel> => {
  if (!locations.length) {
    return undefined
  }
  const locationsSorted = getSortedLocations(locations)
  for (const location of locationsSorted) {
    const conditionValue = inCart
      ? location.locType !== LocationType.VIEW_ONLY &&
        location.qtyAvailable >= minInventory &&
        location.isSelected
      : location.qtyAvailable >= minInventory && location.isSelected
    if (conditionValue) {
      return location
    }
  }
  return locationsSorted[0]
}

const getSortedLocations = (
  locations: Array<ProductLocationModel>
): Array<ProductLocationModel> => {
  if (!locations.length) {
    return []
  }
  return [...locations].sort((a, b) => {
    try {
      return Number(a.locationId) - Number(b.locationId)
    } catch (_e) {
      return 0
    }
  })
}
