import { createRef, ReactElement, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import BaseDrawer from 'src/ui-components/modals/BaseDrawer'
import { I18n, Translate } from 'src/i18n'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { Box, Grid, MenuItem, Select } from '@mui/material'
import { useUserStore } from 'src/store/user/UserStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { Button, TextInput } from 'src/ui-components'
import { breakpoints } from 'src/theme/breakpoints'
import { Send } from '@mui/icons-material'
import { defaultTheme } from '../../../../theme'
import {
  PriorityType,
  ContactUsInfo,
  ContactUsFormErrorsInterface,
} from 'src/api/contactUs/contactusInterface'
import { ContactInfo } from 'src/api/cart/interfaces'
import supportServiceProvider from 'src/services/SupportServiceProvider'
import UserDataServiceProvider from 'src/services/UserDataServiceProvider'
import { OrgType } from 'src/services/attributes/AttributesModel'

interface ContactUsDrawerProps {
  open: boolean
  onCloseDrawer: () => void
}

export const ContactUsDrawer = observer(
  (props: ContactUsDrawerProps): ReactElement => {
    const { open, onCloseDrawer } = props
    const user = useUserStore()
    const uiStore = useUiStateStore()
    const [storeContact, setStoreContact] = useState<ContactInfo | null>(null)
    const [contactUsSpinner, setContactUsSpinner] = useState(false)
    const { parentOrgId, orgType, orgId } = user.preferences
    const screenName = user?.preferences?.screenName
    const userEmail = user?.preferences?.userEmailId

    const priorityOptions = [
      { value: PriorityType.urgent, label: Translate('urgent') },
      { value: PriorityType.today, label: Translate('today') },
      { value: PriorityType.tomorrow, label: Translate('tomorrow') },
    ]

    const contactUSData = {
      name: screenName ? screenName : '',
      email: userEmail ? userEmail : '',
      subject: '',
      body: '',
      priority: PriorityType.today,
      screenName: '',
    }

    const [contactUsForm, setContactUsForm] = useState<ContactUsInfo>({
      ...contactUSData,
    })

    const [messageSent, setMessageSent] = useState(false)
    const [drawerCustomRefs, setDrawerCustomRefs] = useState([])

    const [formErrors, setFormErrors] = useState<ContactUsFormErrorsInterface>({
      name: false,
      email: false,
      subject: false,
      body: false,
    })

    useEffect(() => {
      setDrawerCustomRefs(
        priorityOptions.map(
          (_, i) => drawerCustomRefs[i] || createRef<HTMLElement>()
        )
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      const contactId = orgType === OrgType.LOC ? parentOrgId : orgId
      if (open) {
        UserDataServiceProvider.contactAdressInfo(contactId).then((resp) => {
          setStoreContact(resp)
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const onFormSubmit = () => {
      const { email, subject, name } = contactUsForm
      const emailInvalid = !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      const subjectInvalid = !subject?.length
      const nameInvalid = !(name?.length >= 5)
      setContactUsSpinner(true)
      setFormErrors({
        ...formErrors,
        email: emailInvalid,
        subject: subjectInvalid,
        name: nameInvalid,
      })
      if (emailInvalid || subjectInvalid || nameInvalid) {
        setContactUsSpinner(false)
        return
      } else {
        contactUsForm.screenName = screenName
        supportServiceProvider
          .postContactUs(contactUsForm)
          .then((resp) => {
            if (resp) {
              setMessageSent(true)
              setFormErrors({
                name: false,
                email: false,
                subject: false,
                body: false,
              })

              setContactUsForm({ ...contactUSData })
            }
          })
          .catch((error) => {
            if (error && typeof error === 'string') {
              uiStore.displayErrorNotification(error)
            } else {
              uiStore.displayErrorNotification('contactUsFormErrorMessage')
            }
          })
          .finally(() => {
            setContactUsSpinner(false)
          })
      }
      setMessageSent(false)
    }

    const closeDrawer = () => {
      onCloseDrawer()
      setMessageSent(false)
      setContactUsSpinner(false)
      setContactUsForm({ ...contactUSData })
    }

    const drawerHeader = !storeContact ? (
      <TooltipSpinner animation="border" />
    ) : (
      <Grid container direction="row" className="mt-3">
        <Grid
          item
          style={{
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          <span style={{ fontWeight: 700 }}>{storeContact?.name}</span>
          <br />
          <span style={{ fontWeight: 700 }}>
            {storeContact?.address?.line1}
          </span>
          <br />
          {storeContact?.address?.city}, {storeContact?.address?.region}{' '}
          {storeContact?.address?.postalCode}
          <br />
          {storeContact?.phone?.phoneNumber}
        </Grid>
      </Grid>
    )

    const successDrawerBody = (
      <div className="my-5">
        <Grid container>
          <SuccessGrid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Send
                fontSize="large"
                htmlColor={defaultTheme.colors.greyMedium}
              />
            </Box>
          </SuccessGrid>
          <SuccessGrid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <I18n name="messageSent" />
            </Box>
          </SuccessGrid>
          <Grid item xs={12} className="my-3">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box
                display="flex"
                width="75%"
                justifyContent="center"
                alignItems="center"
              >
                <I18n name="messageSentSuccess" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} className="my-3">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                onClick={() => setMessageSent(false)}
                text={Translate('sendAnother')}
                size="small"
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    )

    const drawerBody = (
      <Grid container className="my-2">
        <Grid
          item
          xs={12}
          sx={{ fontWeight: 700, fontSize: 20, fontFamily: 'Oswald' }}
        >
          <I18n name="sendUsaMessage" />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            width="auto"
            type="text"
            label="name"
            onChange={(name) => setContactUsForm({ ...contactUsForm, name })}
            value={contactUsForm.name}
            required
            error={formErrors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            width="auto"
            type="text"
            label="email"
            onChange={(email) => setContactUsForm({ ...contactUsForm, email })}
            value={contactUsForm.email}
            required
            error={formErrors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            width="auto"
            type="text"
            label="subject"
            onChange={(subject) =>
              setContactUsForm({ ...contactUsForm, subject })
            }
            error={formErrors.subject}
            value={contactUsForm.subject}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <InputLabel>
              <I18n name="priority" />*
            </InputLabel>
            <Select value={contactUsForm.priority} style={{ width: '150px' }}>
              {priorityOptions.map((option, index) => (
                <MenuItem
                  ref={drawerCustomRefs[index]}
                  key={option.value}
                  value={option.value}
                  onClick={() =>
                    setContactUsForm({
                      ...contactUsForm,
                      priority: option.value,
                    })
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <InputLabel>
              <I18n name="message" />
            </InputLabel>
            <StyledTextArea
              error={formErrors.body}
              name="text"
              onChange={(changeEvent) => {
                const body = changeEvent.target.value
                setContactUsForm({ ...contactUsForm, body })
              }}
            />
          </InputWrapper>
        </Grid>
      </Grid>
    )

    const drawerFooter = (
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="secondary"
              text="cancel"
              onClick={() => closeDrawer()}
            />
            <Button
              loading={contactUsSpinner}
              text="sendMessage"
              onClick={() => onFormSubmit()}
            />
          </Box>
        </Grid>
      </Grid>
    )

    return (
      <BaseDrawer
        open={open}
        title={`${Translate('contactUs')}`}
        onClose={() => closeDrawer()}
        header={drawerHeader}
        clickOutsideCustomRefs={drawerCustomRefs}
        showHeaderDivider
        body={messageSent ? successDrawerBody : drawerBody}
        footer={messageSent ? null : drawerFooter}
        minWidth="0"
        width={window.innerWidth < breakpoints.mobileLarge ? '100%' : '500px'}
        height={window.innerWidth < breakpoints.mobileLarge ? '90vh' : '100%'}
      />
    )
  }
)

const TooltipSpinner = styled(Spinner)`
  align-self: center;
`

const InputWrapper = styled.div`
  margin: 10px;
`

interface StyledTextAreaProps {
  error: boolean
}

const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  margin: 5px;
  border: 1px solid ${defaultTheme.colors.border};
  width: 100%;
  min-height: 150px;
  border: 1px solid
    ${(p) => (p.error ? p.theme.colors.red : p.theme.colors.border)};
`

const InputLabel = styled.div`
  font-family: Roboto, serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0 5px;
  font-weight: bold;
`

const SuccessGrid = styled(Grid)`
  font-weight: 700;
  font-size: 20px;
  font-family: 'Oswald', serif;
`
