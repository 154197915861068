import { useEffect, ReactElement, useCallback } from 'react'
import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { CheckboxSelections } from 'src/features/partsCatalog/Selections/components/CheckboxSelections'
import { VisibleStep } from 'src/store/partsCatalog/PartsCatalog'
import { useLaborLookup } from 'src/store/labor/LaborStore'
import { CatalogNodeType } from 'src/store/partsCatalog'
import styled from 'styled-components'

interface Props {
  partGroupId?: string
}

const PartsCatalog = observer((props: Props): ReactElement => {
  const { partGroupId } = props
  const { lookup: laborPartsCatalog } = useLaborLookup()
  const visibleStep = laborPartsCatalog.getVisibleStep()
  const categoryGroups = laborPartsCatalog.categoryGroups()
  const { categories, fetchCategories, groupPartTypes } = laborPartsCatalog
  const { fetchGroups, fetchPartTypes, resetStore } = laborPartsCatalog

  const fetchData = useCallback(async () => {
    if (categories?.length === 0) {
      try {
        await fetchCategories(partGroupId)
        await fetchGroups(partGroupId)
        await fetchPartTypes()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching labor data:', error)
      }
    }
  }, [
    categories?.length,
    fetchCategories,
    fetchGroups,
    fetchPartTypes,
    partGroupId,
  ])

  useEffect(() => {
    fetchData()
  }, [
    categories,
    fetchCategories,
    fetchData,
    fetchGroups,
    fetchPartTypes,
    partGroupId,
  ])

  useEffect(() => {
    resetStore()
  }, [resetStore])

  return (
    <StyledGrid container spacing={2}>
      <CategoriesSection item xs={4}>
        <CheckboxSelections
          loading={
            visibleStep === VisibleStep.START ||
            laborPartsCatalog.loadingCategories
          }
          title="categories"
          type={CatalogNodeType.CATEGORY}
          list={laborPartsCatalog.categories}
          partsCatalogStore={laborPartsCatalog}
        />
      </CategoriesSection>
      <GroupsSection item xs={4}>
        <CheckboxSelections
          loading={
            visibleStep <= VisibleStep.CATEGORY ||
            laborPartsCatalog.loadingCategoryGroups
          }
          title="group"
          type={CatalogNodeType.GROUP}
          groups={categoryGroups}
          partsCatalogStore={laborPartsCatalog}
          showGfxButtons={false}
        />
      </GroupsSection>
      <DescriptionsSection item xs={4}>
        <CheckboxSelections
          loading={
            visibleStep <= VisibleStep.GROUP ||
            laborPartsCatalog.loadingGroupPartTypes
          }
          title="description"
          type={CatalogNodeType.PART_TYPE}
          groups={groupPartTypes}
          partsCatalogStore={laborPartsCatalog}
        />
      </DescriptionsSection>
    </StyledGrid>
  )
})

const StyledGrid = styled(Grid)`
  margin-left: 0;
`

const CategoriesSection = styled(StyledGrid)`
  padding-left: 0px !important;
`

const GroupsSection = styled(StyledGrid)``

const DescriptionsSection = styled(StyledGrid)``

export default PartsCatalog
