import { makeAutoObservable, reaction } from 'mobx'
import { createContext, useContext } from 'react'
import { IPartsBasketVehicleInfo } from 'src/api/embedded/types/partsBasket-1.1'
import { IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import VehicleServiceProvider from 'src/services/VehicleServiceProvider'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { isVehicleComplete } from 'src/store/cart/Utils'
import { Vehicle } from 'src/store/models/Vehicles'
import { VehicleHistoryItem } from 'src/store/vehicleHistory/interfaces'
import { VehicleSelectorStep } from 'src/ui-components/util/Interfaces'

export enum VehicleWidgetDisplayState {
  select,
  edit,
  view,
}

export enum VehicleWidgetErrorState {
  vehicleNotFound,
  vehicleIncomplete,
  vinDigitCheckFailed,
  none,
}

const VEHICLE_WIDGET_STATE_KEY = 'vehicle-widget-state'

export class VehicleWidgetStore {
  displayState: VehicleWidgetDisplayState

  errorState: VehicleWidgetErrorState

  isDrawerOpened: boolean

  constructor() {
    this.displayState = VehicleWidgetDisplayState.select
    makeAutoObservable(this)
    this.restoreWidgetState()
    this.initReactions()
    this.isDrawerOpened = false
    this.errorState = VehicleWidgetErrorState.none
  }

  private _afterAddVehicle: () => void

  public afterAddVehicle(): () => void {
    return this._afterAddVehicle
  }

  public setAfterAddVehicle(callback: () => void): void {
    this._afterAddVehicle = callback
  }

  setDisplayState = (state: VehicleWidgetDisplayState): void => {
    this.displayState = state
  }

  setErrorState = (state: VehicleWidgetErrorState): void => {
    this.errorState = state
  }

  setIsDrawerOpened = (state: boolean): void => {
    this.isDrawerOpened = state
  }

  clearErrorState = (): void => {
    this.errorState = VehicleWidgetErrorState.none
  }

  lookupVehicleFromHistory = async (
    vehicle: VehicleHistoryItem,
    selectedMsg: string
  ): Promise<boolean> => {
    const success = await this.setCurrentVehicle(vehicle)
    if (success) {
      const msg = `${vehicle?.year?.value} ${vehicle.model?.value} ${selectedMsg}`
      StoreInstances.uiStore.displaySuccessNotification(msg)
      StoreInstances.partsCatalog.resetStore()
    }
    return success
  }

  public setCurrentVehicle = async (vehicle: Vehicle): Promise<boolean> => {
    if (vehicle.vin) {
      await VehicleServiceProvider.getVehicleDetails(vehicle.vin)
        .then((resp) => {
          vehicle.vehicleSpecs = resp.vehicleSpecs
        })
        .catch((error) => {
          throw new Error(JSON.stringify(error))
        })
    }

    StoreInstances.searchStore.setCurrentFieldsSelections(vehicle)
    // Vehicle is updated in store.
    if (isVehicleComplete(vehicle)) {
      const cancelUpdateVehicle =
        await StoreInstances.searchStore.updateCurrentVehicle({
          ...vehicle,
          initialized: true,
        })
      this.setIsDrawerOpened(false)
      this.setDisplayState(VehicleWidgetDisplayState.view)
      return cancelUpdateVehicle
    }
    this.setDisplayState(VehicleWidgetDisplayState.select)
    return false
  }

  public static getActiveVehicleStep = (
    vehicle: Vehicle
  ): VehicleSelectorStep => {
    if (!vehicle?.type?.value) {
      return VehicleSelectorStep.START
    }
    if (!vehicle?.year?.value) {
      return VehicleSelectorStep.YEAR
    }
    if (!vehicle?.make?.value) {
      return VehicleSelectorStep.MAKE
    }
    if (!vehicle?.model?.value) {
      return VehicleSelectorStep.MODEL
    }
    if (!vehicle?.engine?.value) {
      return VehicleSelectorStep.ENGINE
    }
    return VehicleSelectorStep.ENGINE
  }

  lookupVehicle = async (
    vehicle: IPartsBasketVehicleInfo
  ): Promise<boolean> => {
    if (!vehicle) {
      return false
    }
    try {
      const decodedVehicle =
        await VehicleServiceProvider.getDecodedAndValidatedYMME(vehicle)
      if (await this.setCurrentVehicle(decodedVehicle)) {
        return true
      } else {
        const activeStep =
          VehicleWidgetStore.getActiveVehicleStep(decodedVehicle)
        if (activeStep !== VehicleSelectorStep.START) {
          this.setErrorState(VehicleWidgetErrorState.vehicleIncomplete)
        } else {
          this.setErrorState(VehicleWidgetErrorState.vehicleNotFound)
        }

        this.setIsDrawerOpened(true)
        return true
      }
    } catch (_e: unknown) {
      this.setIsDrawerOpened(true)
      this.setDisplayState(VehicleWidgetDisplayState.select)
      this.setErrorState(VehicleWidgetErrorState.vehicleNotFound)
      return false
    }
  }

  // TODO: verify and stop persistig the store.
  public saveWidgetState = (): void => {
    if (IS_INTEGRATED_VERSION) {
      return
    }
    sessionStorage.setItem(VEHICLE_WIDGET_STATE_KEY, JSON.stringify(this))
  }

  // TODO: verify and stop persistig the store.
  public restoreWidgetState = (): void => {
    if (IS_INTEGRATED_VERSION) {
      return
    }
    const savedStateStr = sessionStorage.getItem(VEHICLE_WIDGET_STATE_KEY)
    if (savedStateStr) {
      const savedState = JSON.parse(savedStateStr)
      this.displayState = savedState.displayState
      this.errorState = savedState.errorState
    }
  }

  public initReactions = (): void => {
    reaction(
      () => this.displayState,
      () => {
        this.saveWidgetState()
      }
    )
  }
}

export const vehicleWidgetStore = new VehicleWidgetStore()

export const VehicleWidgetStoreContext =
  createContext<VehicleWidgetStore>(vehicleWidgetStore)

export const useVehicleWidgetStore = (): VehicleWidgetStore => {
  return useContext(VehicleWidgetStoreContext)
}
