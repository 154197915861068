import { IUserActions } from 'src/store/models/IUserActions'
import { BaseServiceProvider } from './BaseServiceProvider'

class UserActionsServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'useractivity', '1.1', '')
  }

  sendUserActions = async (reqData: IUserActions): Promise<IUserActions> => {
    const resp = await this.post<IUserActions>('cartLostSales', reqData)
    return resp.data
  }
}

export default new UserActionsServiceProvider()
